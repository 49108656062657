<template>
  <v-container fluid>
    <v-row class="ma-0">
      <v-col class="px-1 col-ajust" :style="{ fontSize: '1.25em' }">
        <v-chip label :color="color">
          {{ this.tabDetails.vendStepDescr }}
        </v-chip>
      </v-col>
      <v-col
        class="px-1 col-ajust"
        :style="{ fontSize: '1.25em', flex: 'none', width: 'auto' }"
      >
        <v-chip label :color="color" v-if="this.tabDetails.docType !== 'Quote'">
          {{ parseFromIsoToFormat(this.tabDetails.dueDate, "yyyy-LL-dd") }}
        </v-chip>
      </v-col>
      <v-col
        class="px-1 col-ajust ml-auto"
        :style="{ fontSize: '1.25em', flex: 'none', width: 'auto' }"
        v-show="isTBD.length"
      >
        <v-chip
          label
          :color="
            isTBD === 'TBD'
              ? 'green darken-1'
              : isTBD === 'DETERMINED'
              ? 'deep-purple lighten-1'
              : 'cyan lighten-1'
          "
        >
          {{ isTBD }}
        </v-chip>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col
        v-for="{ name, color, tooltipText, method } in buttonsName"
        :key="name"
        class="col-ajust px-1 py-1"
      >
        <template>
          <v-tooltip
            bottom
            color="primary"
            :open-on-hover="!!tooltipText.length"
            :open-on-focus="!!tooltipText.length"
            :open-on-click="!!tooltipText.length"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                class="button elevation-5"
                small
                :color="color || 'primary'"
                v-on="on"
                @click="handlerEventButton(method)"
              >
                {{ name }}
              </v-btn>
            </template>
            <span>{{ tooltipText }}</span>
          </v-tooltip>
        </template>
      </v-col>
      <v-col class="col-ajust px-1 py-1" style="margin-left: 25px">
        <template>
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
              <v-btn
                class="button elevation-5"
                small
                color="green"
                v-on="on"
                @click="refresh"
                outlined
              >
                <v-icon>mdi-cached</v-icon>
              </v-btn>
            </template>
            <span>Refresh</span>
          </v-tooltip>
        </template>
      </v-col>
      <v-col class="col-ajust px-1 py-1">
        <template>
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
              <v-btn
                class="button elevation-5"
                small
                color="red darken-4"
                v-on="on"
                @click="cancelOrder"
                outlined

              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Cancel Order</span>
          </v-tooltip>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations, mapActions, mapGetters, mapState } from "vuex";
import { parseFromIsoToFormat } from "@/helpers/mocks/dates.js";
import { update_order_pipeline } from "@/helpers/api/Orders";
import { remote_upload_pdf_to_order } from "@/helpers/api/Orders/index.js";
import { trans_log_order } from "@/helpers/api/Orders/index.js";

import axios from "axios";

export default {
  porps: [],
  computed: {
    ...mapState({
      objPickedUpArr: (state) => state.moduleOrders.moduleDetails.objPickedUpArr,
    }),
    ...mapState(['releaseDeleted']),
    ...mapGetters({
      tabDetails: "moduleOrders/tabDetails",
      docNo: "moduleOrders/docNo",
      isTBD: ["moduleOrders/moduleDetails/isTBD"],
    }),

    color() {
      const colors = {
        Quote: "grey",
        New: "indigo",
        Paid: "green",
        "Pending Pick Up": "purple",
        Completed: "green darken-4",
        Closed: "red lighten-1",
        "---": "primary",
      };
      return colors[this.tabDetails["vendStepDescr"]];
    },
    buttonsName() {
      let statuses = {
        Quote: [
          {
            name: "Print/Send Quote",
            tooltipText: "Print Quote",
            method: "downloadQuote",
          },
          {
            name: "Generate Open Order",
            tooltipText: "",
            method: "quote2OpenOrder",
          },
        ],
        New: [
          {
            name: "Print/Send Bill of Sale",
            tooltipText: "",
            method: "downloadInvoiceNew",
          },
          {
            name: "Customer Payment",
            tooltipText: "",

            method: "showCustomerPayment",
          },
        ],
        Paid: [
          {
            name: "Assign & Send Release",
            tooltipText: "Issue Release for Paid-For Units",
            method: "setupRelease",
          },
        ],
        "Pending Pick Up": [
          // {
          //   name: "Send Pickup reminder",
          //   tooltipText: "Send Pickup reminder to Buyer",
          //   method: "printViewRelease",
          // },
        ],
        Completed: [
          // {
          //   name: "Request Rebill",
          //   tooltipText: "Request Rebill from Finance/ERP",
          // },
          // {
          //   name: "Send Rebill ",
          //   tooltipText: "Send Rebill to Buyer",
          // },
          {
            name: "Close Order",
            tooltipText: "",
            color: "red lighten-1",
            method: "closeOrder",
          },
        ],
        Closed: [
          // {
          //   name: "Request Rebill",
          //   tooltipText: "Request Rebill from Finance/ERP",
          // },
          // {
          //   name: "Send Rebill ",
          //   tooltipText: "Send Rebill to Buyer",
          // },
        ],
      };

      return statuses[`${this.tabDetails["vendStepDescr"]}`.trim()];
    },
    pendingPickUpStatus() {
      return (
        this.tabDetails["vendStepDescr"] &&
        this.tabDetails["vendStepDescr"].trim() !== "Pending Pick Up"
      );
    },
  },
  data() {
    return {};
  },
  watch: {
    releaseDeleted(newVal) {
      if (newVal) {
        this.refresh();
      }
    }
  },
  methods: {
    parseFromIsoToFormat,
    ...mapActions({
      actionCancelOrder: "moduleOrders/cancelOrder",
      actionCloseOrder: "moduleOrders/closeOrder",
      actionQuote2OpenOrder: "moduleOrders/quote2OpenOrder",
      getOrderByDocNo: "moduleOrders/moduleDetails/getOrderByDocNo",
      updateDetails: "moduleOrders/moduleDetails/updateDetails",
      actionRefreshAll: "moduleOrders/moduleDetails/refreshAllPanels",
    }),
    ...mapMutations({
      setDetailsStore: "moduleOrders/setDetails",
    }),
    returnRouterOrders() {
      this.setDetailsStore({});
      this.$router.replace("/orders");
    },
    handlerEventButton(method) {
      if (this[method]) this[method]();
    },
    async cancelOrder() {
      const isOk = confirm("Are you sure you want to cancel the order?");

      if (!isOk) return;

      let { docType, id } = this.tabDetails;
      await this.actionCancelOrder({
        docType,
        orderId: id,
      });

      // this.returnRouterOrders();

      setTimeout(function () {
        window.location.reload();
      }, 1000);
    },
    async downloadInvoiceNew() {
      const token = localStorage.getItem("access_token");
      let { docNo, id } = this.tabDetails;
      const url_new = process.env.VUE_APP_FILES_URL;
      const bos_options_new_url = `BOS?invoiceID=${docNo}&token=${token}`;

      window.open(`${url_new}/${bos_options_new_url}`, "_blank");

      this.updateDetails({ name: "" });

      try {
        trans_log_order({
          order_id: id,
          doc_table: "invoices",
          trans_desc: "Bill of Sale print/view opened",
          application: "PDF Gen Service",
          trans_action: "Print",
          old_value: "",
          new_value: "",
        });
      } catch (error) {
        console.log(error);
      }

      remote_upload_pdf_to_order({
        url: `https://nereus-inf-pdf-gen-service-development-vjuqlq6gsq-uw.a.run.app/?url=${url_new}/${bos_options_new_url}`,

        docType: "SALEINVOICE",
        category: "BOS",
        sourceType: "FILEATTACH",
        sourceid: docNo,
        permissions: "write,read",
        organization_id: null,
      });
    },

    async downloadQuote() {
      const self = this;

      const token = localStorage.getItem("access_token");
      let { docNo, id } = await this.tabDetails;
      const url = process.env.VUE_APP_SCRIPTCASE_API_PATH;
      window.open(
        `${url}/rptVendToCustQuote/?v_quoteID=${id}&v_quoteNO=${docNo}&token=${token}`,
        "_blank"
      );

      // try {
      //   trans_log_order({
      //     order_id: id,
      //     doc_table: "quotes",
      //     trans_desc: "Quote print/view opened",
      //     application: "rptVendToCustQuote",
      //     trans_action: "Print",
      //     old_value: "",
      //     new_value: "",
      //   });
      // } catch (error) {
      //   console.log(error);
      // }

      remote_upload_pdf_to_order({
        url: `https://pdf-gen-service-vjuqlq6gsq-uw.a.run.app/?url=${url}/rptVendToCustQuote/?v_quoteID=${id}&v_quoteNO=${docNo}&token=${token}`,
        docType: "SALEINVOICE",
        category: "QUOTE",
        sourceType: "FILEATTACH",
        sourceid: docNo,
        permissions: "write,read",
        organization_id: null,
      });
    },

    async quote2OpenOrder() {
      this.$store.commit("setSpinnerShow", true);

      const token = localStorage.getItem("access_token");
      let { docNo, id } = await this.tabDetails;
      const url = process.env.VUE_APP_SCRIPTCASE_API_PATH;

      const { data } = await axios.post(
        `https://corsanywhere-vjuqlq6gsq-wl.a.run.app/${url}/api_quote2invoice2_vendapp/?token=${token}`,
        {
          id: id,
        }
      );

      this.$store.commit("setSpinnerShow", false);
      this.getOrderByDocNo(data.invoiceNo);
      this.$router.push(`/orders/${data.invoiceNo}`);
    },
    setupRelease() {
      this.$emit("showIssueRelease", true);
    },
    showCustomerPayment() {
      this.$emit("showCustomerPayment", true);
    },

    async updateOrderPipeline() {
      this.$store.commit("setSpinnerShow", true);

      let { id, docNo } = await this.tabDetails;
      const resData = await update_order_pipeline({ invoice_id: id });

      this.$store.commit("setSpinnerShow", false);
      window.location.reload(1);

      this.$router.push({
        path: this.getTo,
        query: { timestamp: Date.now() }, // changes every time when clicked called
      });
    },

    async refresh() {
      this.$store.commit("setSpinnerShow", true);
      let { docNo, id } = await this.tabDetails;
      console.log("ItemsPanelAction - { docNo, id }", [docNo, id]);

      await update_order_pipeline({ invoice_id: id });

      this.$store.commit("setSpinnerShow", false);
      this.actionRefreshAll({ docNo, id });
    },
    async closeOrder() {
      let { id, docNo } = await this.tabDetails;
      const isOk = confirm("Are you sure you want to CLOSE and LOCK this order?");

      if (!isOk) return;

      await this.actionCloseOrder({
        docType: "Invoice",
        orderId: id,
      });

      this.$store.commit("setSpinnerShow", false);
      this.getOrderByDocNo(docNo);
    },
    async printViewRelease() {
      const token = localStorage.getItem("access_token");
      let { docNo, id } = this.tabDetails;
      const url = process.env.VUE_APP_SCRIPTCASE_API_PATH;
      window.open(
        `${url}/rptVendToCustRelNote/?v_invoiceID=${id}&v_invoiceNO=${docNo}&token=${token}`,
        "_blank"
      );
      this.getOrderByDocNo(docNo);

      try {
        trans_log_order({
          order_id: id,
          doc_table: "release_note",
          trans_desc: "Release print/view opened",
          application: "rptVendToCustQuote",
          trans_action: "Print",
          old_value: "",
          new_value: "",
        });
      } catch (error) {
        console.log(error);
      }

      remote_upload_pdf_to_order({
        url: `https://pdf-gen-service-vjuqlq6gsq-uw.a.run.app/?url=${url}/rptVendToCustRelNote/?v_invoiceID=${id}&v_invoiceNO=${docNo}&token=${token}`,
        docType: "SALEINVOICE",
        category: "REL",
        sourceType: "FILEATTACH",
        sourceid: docNo,
        permissions: "write,read",
        organization_id: null,
      });
    },
  },
};
</script>
